import type { Typographies } from '../types';

const baseFontFamily = 'DM Sans';

const typographies: Typographies = {
  'display-1': {
    fontSize: '3.75rem',
    lineHeight: '4.5rem',
    fontFamily: baseFontFamily,
    fontWeight: 700,
  },
  'display-2': {
    fontSize: '2.5rem',
    lineHeight: '3.25rem',
    fontFamily: baseFontFamily,
    fontWeight: 700,
  },
  'display-3': {
    fontSize: '1.75rem',
    lineHeight: '2.25rem',
    fontFamily: baseFontFamily,
    fontWeight: 700,
  },
  'display-4': {
    fontSize: '21px',
    lineHeight: '32px',
    fontFamily: baseFontFamily,
    fontWeight: 400,
  },
  'heading-1': {
    fontSize: '24px',
    lineHeight: '32px',
    fontFamily: baseFontFamily,
    fontWeight: 700,
  },
  'heading-2': {
    fontSize: '21px',
    lineHeight: '32px',
    fontFamily: baseFontFamily,
    fontWeight: 700,
  },
  'heading-3': {
    fontSize: '19px',
    lineHeight: '28px',
    fontFamily: baseFontFamily,
    fontWeight: 700,
  },
  'body-1': {
    fontSize: '18px',
    lineHeight: '28px',
    fontFamily: 'DM Sans',
    fontWeight: 400,
  },
  'body-2': {
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'DM Sans',
    fontWeight: 400,
  },
  'body-3': {
    fontSize: '15px',
    lineHeight: '20px',
    fontFamily: 'DM Sans',
    fontWeight: 400,
  },
  'body-4': {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'DM Sans',
    fontWeight: 400,
  },
  'label-1': {
    fontSize: '18px',
    lineHeight: '28px',
    fontFamily: 'DM Sans',
    fontWeight: 500,
  },
  'label-2': {
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'DM Sans',
    fontWeight: 500,
  },
  'label-3': {
    fontSize: '15px',
    lineHeight: '20px',
    fontFamily: 'DM Sans',
    fontWeight: 500,
  },
  'label-4': {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'DM Sans',
    fontWeight: 500,
  },
  'caption-1': {
    fontSize: '13px',
    lineHeight: '16px',
    fontFamily: 'DM Sans',
    fontWeight: 500,
  },
  'caption-2': {
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'DM Sans',
    fontWeight: 500,
  },
  'snippet-1': {
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Source Code Pro',
    fontWeight: 400,
  },
  'snippet-2': {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Source Code Pro',
    fontWeight: 400,
  },
};

export default typographies;
