import type { BorderRadiuses } from '../types';

const borderRadius: BorderRadiuses = {
  0: 0,
  1: 6,
  2: 24,
  3: 1000,
};

export default borderRadius;
